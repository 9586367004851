@import "../../../../../node_modules/@causevest/ui-kit/variables";

.form {
  margin-top: 10px;
  gap: 20px;
  padding: 0;

  &__title {
    font-size: 18px;
    font-weight: 300;
  }

  &__social {
    width: 100%;
    align-items: center;
    gap: 15px;
  }

  &__inner {
    align-items: center;
    gap: 30px;
    margin-top: 10px;
  }

  &__body {
    width: 100%;
    align-items: center;
    gap: 15px;
  }

  &__row {
    width: 100%;
    gap: 20px;

    > * {
      width: 100%;
    }
  }

  &__right {
    width: 200px;
    padding: 10px 15px 15px;
    border-radius: 6px;
    border: 1px solid $color-light-grey;

    &_text {
      font-size: 14px;
      color: $color-black-5;
    }
  }

  &__reminder {
    flex-direction: row;
    align-items: flex-start;
    gap: 15px;
    max-width: 400px;
    padding: 10px 15px 15px;
    border-radius: 6px;
    border: 1px solid $color-light-grey;

    &_text {
      font-size: 14px;
      color: $color-black-5;
    }
  }
}

@media (max-width: 520px) {
  .form {
    &__row {
      flex-direction: column;
    }
  }
}
