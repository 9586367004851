@import "../../../../../../node_modules/@causevest/ui-kit/variables";

.wrapper {
  margin: auto;
  align-items: center;
  background: $color-white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 30px rgba(13, 58, 115, 0.1);
  text-align: center;
}