@import "../../../../../../node_modules/@causevest/ui-kit/variables";

.wrapper {
  max-width: 400px;
}

.googleBtn > button {
  margin: 0 auto;
}

.question {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 15px auto 0 auto;
  color: $color-black-5;
  font-size: 14px;
}

.signup {
  font-size: 14px !important;

  &:hover {
    background: transparent;
  }
}

.inputs {
  margin-top: 10px;
  gap: 15px;
}

.forgotPass {
  margin: 15px 0;
  font-size: 14px !important;

  &:hover {
    background: transparent;
  }
}

.loginBtn {
  height: 40px;
  border-radius: 20px !important;
}

.info {
  font-size: 12px;
  color: $color-grey-1;
  margin-top: 20px;
}